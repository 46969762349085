<template>
  <div class="product">
    <el-container>
      <!-- 页头 -->
      <el-header>
        <div class="title">
          <div class="image" @click="shouye">
            <el-carousel
              height="80px"
              direction="vertical"
              arrow="never"
              indicator-position="none"
              :interval="5000"
            >
              <el-carousel-item>
                <img src="../assets/img/logo.png" />
              </el-carousel-item>
              <el-carousel-item>
                <img src="../assets/img/logo2.png" />
              </el-carousel-item>
              <el-carousel-item>
                <img src="../assets/img/logo.png" />
              </el-carousel-item>
              <el-carousel-item>
                <img src="../assets/img/logo2.png" />
              </el-carousel-item>
            </el-carousel>
          </div>
          <div class="titleBtn">
            <div @click="shouye">首页</div>
            <div class="active" @click="shanpin">产品中心</div>
            <div @click="zixun">资讯动态</div>
            <div @click="zhaoxian">招贤纳士</div>
            <div @click="yuedong">走进跃动</div>
            <div @click="lianxi">联系我们</div>
          </div>
        </div>
      </el-header>
      <!-- 主体 -->
      <el-main>
        <div class="productback">
          <div>打造全方位智能化场所客户服务体系</div>
          <div>
            CREATE AN ALL-ROUND INTELLIGENT PLACE CUSTOMER SERVICE SYSTEM
          </div>
        </div>
        <div class="yinyong">
          <div class="yinyongdiv">
            <!-- 场所应用 -->
            <div>场所应用</div>
            <div class="changsuo">
              <div class="card" @click="tiaozhuan(`http://www.8quan.com/#/`)">
                <div class="title">
                  <img src="../assets/img/card1.png" />
                  <div class="text">8圈智能场所管理平台</div>
                </div>
                <div class="cardmain">
                  为上网服务营业场所量身打造的智能管理系统，提供全方位的管理、营销和增值服务方案，为场所提供专属智能管理服务。
                </div>
                <div class="foot">
                  <el-link :underline="false">查看详情</el-link>
                  <img src="../assets/img/right.png" />
                </div>
              </div>
              <div
                class="card"
                @click="tiaozhuan(`http://www.8quan.com/#/robot0`)"
              >
                <div class="title">
                  <img src="../assets/img/card2.png" />
                  <div class="text">
                    <div>8圈机器人</div>
                    <div>8圈智能场所自助服务系统</div>
                  </div>
                </div>
                <div class="cardmain">
                  为了提升场所智慧经营，提高场所收入，精心打造的一款集Al人脸识别、无人收银系统、网民自助上网、智能财务管理等功能于一体的智能自助服务设备，与8圈计费完美结合，实现数据共享，统一云端存储，信息管理更安全。
                </div>
                <div class="foot">
                  <el-link :underline="false">查看详情</el-link>
                  <img src="../assets/img/right.png" />
                </div>
              </div>
              <div
                class="card"
                @click="tiaozhuan(`http://www.8quan.com/#/eagleEye0`)"
              >
                <div class="title">
                  <img src="../assets/img/card1.png" />
                  <div class="text">
                    <div>8圈鹰眼</div>
                    <div>8圈智能场所会员识别系统</div>
                  </div>
                </div>
                <div class="cardmain">
                  8圈鹰眼系统应用Al技术对场所内人员自动识别，并进行会员信息识别认证，为会员提供个性化服务，同时去上网APP接收到相关信息，实现快速上网。
                </div>
                <div class="foot">
                  <el-link :underline="false">查看详情</el-link>
                  <img src="../assets/img/right.png" />
                </div>
              </div>
            </div>
            <!-- 移动APP -->
            <div style="margin-top: 62px">移动APP</div>
            <div class="yidong">
              <div
                class="card"
                @click="tiaozhuan(`http://www.8quan.com/#/gonetwork0`)"
              >
                <div class="title">
                  <img src="../assets/img/card3.png" />
                  <div class="text">
                    <div>去上网APP</div>
                    <div>便捷上网系统</div>
                  </div>
                </div>
                <div class="cardmain">
                  一款以方便网民、简化上网流程、降低场所经营成本为理念所开发的移动APP，为网民提供便捷、安全、有效的上网方式。
                </div>
                <div class="foot">
                  <el-link :underline="false">查看详情</el-link>
                  <img src="../assets/img/right.png" />
                </div>
              </div>
              <div
                class="card"
                @click="tiaozhuan(`http://www.8quan.com/#/palm0`)"
              >
                <div class="title">
                  <img src="../assets/img/card1.png" />
                  <div class="text">
                    <div>掌上8圈APP</div>
                    <div>智能场所经营状况实时查询软件</div>
                  </div>
                </div>
                <div class="cardmain">
                  专为上网服务营业场所经营者提供实时查询经营状况的助手APP。提供场所经营数据统计、消息推送、手机提现、行业资讯等服务，满足管理者随时随地了解场所经营情况的需求。
                </div>
                <div class="foot">
                  <el-link :underline="false">查看详情</el-link>
                  <img src="../assets/img/right.png" />
                </div>
              </div>
            </div>
          </div>
        </div>
      </el-main>
      <!-- 底部 -->
      <footerComponents></footerComponents>
    </el-container>
  </div>
</template>
<script>
import footerComponents from "../components/footer.vue";
export default {
  name: "Product",
  components: { footerComponents },
  data() {
    return {};
  },
  mounted() {
    if (
      /Android|webOS|iPhone|iPod|BlackBerry|Harmony/i.test(navigator.userAgent)
    ) {
      //移动设备打开的网页，缩放页面尺寸
      /*
  		var clientWidth = document.body.clientWidth;
  		var rate = clientWidth / 1920;
  		*/
      var rate = 0.1;
      var meta = document.getElementsByTagName("meta");
      meta["viewport"].setAttribute(
        "content",
        "width=device-width,initial-scale=" + rate
      );
    }
  },
  methods: {
    //跳转首页
    shouye() {
      this.$router.push("/");
    },
    //跳转产品中心
    shanpin() {
      this.$router.push("/product");
    },
    //跳转资讯动态
    zixun() {
      this.$router.push("/information");
    },
    //跳转招贤纳士
    zhaoxian() {
      this.$router.push("/careers");
    },
    //跳转走进跃动
    yuedong() {
      this.$router.push("/yedone");
    },
    //联系我们
    lianxi() {
      this.$router.push("/contactUs");
    },
    //跳转产品
    tiaozhuan(e) {
      window.open(e);
    },
  },
};
</script>
<style lang="less" scoped>
// 页头样式
.el-header {
  width: 100%;
  min-width: 1480px;
  padding: 0;
  margin: 0;
  height: 80px !important;
  line-height: 80px;
  .title {
    display: flex;
    align-items: center;
    width: 1200px;
    margin: 0 auto;
    .image {
      min-width: 158px;
      height: 80px;
      margin-right: 52px;
      img {
        width: 158px;
        height: 40px;
        padding-top: 20px;
      }
      &:hover {
        cursor: pointer;
      }
    }
    .titleBtn {
      display: flex;
      align-items: center;
      height: 80px;
      box-sizing: border-box;
      > div {
        margin-left: 68px;
        width: 72px;
        height: 27px;
        font-size: 18px;
        font-family: Source Han Sans SC;
        font-weight: 500;
        line-height: 27px;
        color: #666666;
        text-align: center;
        &:hover {
          cursor: pointer;
        }
      }
      .active {
        position: relative;
        color: #004097;
      }
      .active::after {
        position: absolute;
        bottom: -10px;
        content: "";
        width: 30px;
        height: 4px;
        background: #004097;
        left: 50%;
        transform: translate(-50%);
      }
    }
  }
}
.el-main {
  width: 100%;
  min-width: 1480px;
  padding: 0;
  margin: 0 auto;
  .productback {
    height: 580px;
    background-image: url(../assets/img/productback.png);
    background-size: cover;
    /* 将背景图片等比缩放填满整个容器 */
    background-repeat: no-repeat;
    background-position: center;
    box-sizing: border-box;
    padding-top: 200px;
    margin: 0 auto;
    text-align: center;
    font-family: Source Han Sans SC;
    color: #ffffff;
    :nth-child(1) {
      font-size: 48px;
      font-weight: 500;
      line-height: 58px;
    }
    :nth-child(2) {
      font-size: 18px;
      font-weight: 300;
      line-height: 20px;
      margin-top: 8px;
    }
  }

  .yinyong {
    height: 932px;
    padding-top: 60px;
    box-sizing: border-box;
    background: #f4f5f9;
    .yinyongdiv {
      width: 1200px;
      height: 812px;
      margin: 0 auto;
      font-size: 36px;
      font-family: Source Han Sans SC;
      font-weight: bold;
      line-height: 36px;
      color: #161e2a;
      .changsuo,
      .yidong {
        display: flex;
        flex-wrap: wrap;
        align-items: center;
        .card {
          width: 380px;
          height: 302px;
          margin-top: 30px;
          margin-left: 30px;
          background: #ffffff;
          border-radius: 12px;
          position: relative;
          &:hover {
            cursor: pointer;
            box-shadow: 0px 5px 20px rgba(90, 97, 107, 0.1);
          }
          // 调整第一个和第四个左边距为空
          &:first-child,
          &:nth-child(4) {
            margin-left: 0;
          }
          .title {
            margin: 30px auto 0;
            display: flex;
            align-items: center;
            img {
              width: 68px;
              height: 68px;
              margin: 0 12px 0 30px;
            }
            .text {
              font-size: 24px;
              font-weight: 500;
              line-height: 40px;
              color: #161e2a;
              div:nth-child(2) {
                font-size: 14px;
                font-family: Source Han Sans SC;
                font-weight: 400;
                line-height: 18px;
                color: #8c97a8;
                margin-top: 4px;
              }
            }
          }
          .cardmain {
            font-size: 14px;
            font-weight: 400;
            line-height: 23px;
            color: #626778;
            padding: 20px 30px 0;
          }
          .foot {
            display: flex;
            align-items: center;
            position: absolute;
            left: 30px;
            bottom: 28px;
            .el-link {
              font-size: 14px;
              font-weight: 400;
              color: #004097;
              margin-right: 4px;
            }
          }
        }
      }
      .yidong {
        .card {
          height: 280px;
        }
      }
    }
  }
}
</style>
